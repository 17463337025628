<template>
  <nav class="global" :class="{ hidden: !open }">
    <a href="" class="close" @click.prevent="$emit('close', true)"></a>

    <img src="~@/assets/i/ife-one-logo-blue.svg" alt="" class="logo" />

    <div class="container">
      <div class="columns">
        <div class="left-col">
          <p class="header">
            {{ $t("navigation.main") }}
          </p>
          <p class="header">
            <router-link
              :to="{ name: 'Main' }"
              class="header"
              active-class="active"
              >{{ $t("navigation.entertainment") }}</router-link
            >
          </p>
          <ul>
            <li :key="index" v-for="(item, index) in items">
              <router-link
                active-class="selected"
                :to="{
                  name: 'MovieList',
                  params: {
                    section: item.id,
                    collection: item.collections[0].id
                  }
                }"
                >{{ item.title }}</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'Favorites' }"
                active-class="selected"
                >{{ $t("navigation.favorites") }}</router-link
              >
            </li>
          </ul>
        </div>

        <div class="right-col">
          <p class="header"></p>
          <ul class="col-r-p"></ul>
          <ul class="chooser">
            <li
              :class="{
                selected: $i18n.locale === 'ru',
                disabled: $i18n.locale === 'ru'
              }"
            >
              <a
                href="#"
                v-if="$i18n.locale !== 'ru'"
                @click.prevent="setLocale('ru')"
                ><img src="@/assets/i/flag-ru.png" alt="" />Русский</a
              >
              <span class="disabled" v-else
                ><img src="@/assets/i/flag-ru.png" alt="" />Русский</span
              >
            </li>
            <li
              :class="{
                selected: $i18n.locale === 'en',
                disabled: $i18n.locale === 'en'
              }"
            >
              <a
                href="#"
                v-if="$i18n.locale !== 'en'"
                @click.prevent="setLocale('en')"
                ><img src="@/assets/i/flag-en.png" alt="" />English</a
              >
              <span class="disabled" v-else
                ><img src="@/assets/i/flag-en.png" alt="" />English</span
              >
            </li>
          </ul>

          <div class="buttons">
            <router-link
              to="/captive"
              v-if="isFlight && !isAuth"
              class="btn auth"
              >{{ $t("navigation.authorization_header_nav") }}</router-link
            ><br />
            <router-link to="/help" class="btn help">{{
              $t("navigation.help")
            }}</router-link
            ><br />
            <router-link
              to="/feedback"
              class="btn feedback"
              :class="{ disabled: !isFlight || !isAuth }"
              >{{ $t("navigation.feedback") }}</router-link
            >
          </div>

          <div class="links">
            <router-link to="/privacy" class="privacy">{{
              $t("languages.privacy")
            }}</router-link
            ><br />
            <router-link to="/license" class="license">{{
              $t("languages.license")
            }}</router-link>
          </div>

          <div class="legals">
            <p>&copy; Copyright Premier IFE. Technics, 2021</p>
            <p>&copy; Content is a copyright of respective content owners.</p>
            <p>&copy; Entertainment program is a copyright by AeroGroup llc.</p>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import isFlight from "@/mixins/isFlight";

export default {
  name: "Navigation",
  props: ["open", "items"],
  mixins: [isFlight],
  watch: {
    open(value) {
      if (value) {
        document.querySelector("html").classList.add("navigation-opened");
      } else {
        document.querySelector("html").classList.remove("navigation-opened");
      }
    }
  },
  beforeDestroy() {
    document.querySelector("html").classList.remove("navigation-opened");
  },
  methods: {
    ...mapMutations({
      setSize: "SET_SIZE"
    }),
    ...mapActions({
      setLocale: "SET_LOCALE"
    })
  }
};
</script>

<style scoped></style>
