var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('ul',{staticClass:"navigation"},[_c('li',{staticClass:"nav"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('openNavigation', true)}}},[_c('img',{attrs:{"alt":"","src":require("@/assets/i/nav.svg")}})])]),_c('li',{staticClass:"local-nav"},[(_vm.currentSection && !_vm.$route.params.section)?_c('router-link',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"},attrs:{"to":{
          name: 'MovieList',
          params: {
            section: _vm.currentSection.id,
            collection: _vm.currentSection.collections[0].id
          }
        }}},[_c('img',{attrs:{"src":require("@/assets/i/arr-left.svg"),"alt":""}}),_c('span',{staticStyle:{"overflow-y":"hidden"}},[_vm._v(_vm._s(_vm.currentSection.title))])]):(_vm.$route.params.section)?_c('router-link',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"},attrs:{"to":{ name: 'Main' }}},[_c('img',{attrs:{"src":require("@/assets/i/arr-left.svg"),"alt":""}}),_c('span',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"}},[_vm._v(_vm._s(_vm.$t("navigation.entertainment")))])]):(_vm.$router.currentRoute.name === 'Press')?_c('router-link',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"},attrs:{"to":{ name: 'Index' }}},[_c('img',{attrs:{"src":require("@/assets/i/arr-left.svg"),"alt":""}}),_c('span',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"}},[_vm._v(_vm._s(_vm.$t("navigation.press_crumbs"))+" ")])]):(_vm.$router.currentRoute.name === 'PressNumber')?_c('router-link',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"},attrs:{"to":{ name: 'Press' }}},[_c('img',{attrs:{"src":require("@/assets/i/arr-left.svg"),"alt":""}}),_c('span',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"}},[_vm._v(_vm._s(_vm.$t("navigation.press_crumbs"))+" ")])]):(_vm.$router.currentRoute.name === 'PressItem')?_c('router-link',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"},attrs:{"to":{
          name: 'PressNumber',
          params: { number: _vm.$route.params.number }
        }}},[_c('img',{attrs:{"src":require("@/assets/i/arr-left.svg"),"alt":""}}),_c('span',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"}},[_vm._v(_vm._s(_vm.$t("navigation.press_crumbs"))+" ")])]):(_vm.$router.currentRoute.name !== 'Index')?_c('router-link',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"},attrs:{"to":{ name: 'Index' }}},[_c('img',{attrs:{"src":require("@/assets/i/arr-left.svg"),"alt":""}}),_c('span',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"}},[_vm._v(_vm._s(_vm.$t("navigation.home"))+" ")])]):_vm._e()],1),_c('li',{staticClass:"route"},[_c('RouteBar')],1),_c('li',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"/"}},[_c('picture',[_c('source',{attrs:{"srcset":require("@/assets/i/ife-one-logo.svg"),"media":"(max-width: 767px)"}}),(_vm.$i18n.locale === 'ru')?_c('img',{attrs:{"src":require("@/assets/i/ife-one-logo.svg"),"width":"100%","height":"auto","alt":""}}):_c('img',{attrs:{"src":require("@/assets/i/ife-one-logo.svg"),"width":"100%","height":"auto","alt":""}})])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }