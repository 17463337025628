<template>
  <section class="section section-player float">
    <audio ref="audio" :src="item.stream"></audio>
    <div class="container m-container-only-s">
      <div class="player">
        <div class="poster">
          <img :src="replaceCoverImage(cover)" alt="" />
        </div>
        <div class="fluid-container">
          <div class="info">
            <div class="track">
              <div class="name">{{ item.title }}</div>
              <div class="time">-{{ timeStringToEnd }}</div>
            </div>
            <input
              type="range"
              min="0"
              :max="item.durationSec"
              :value="memory.currentTime"
              step="0.01"
              :style="timeStyle"
              @input="onSeekChange"
              class="progress"
            />
            <div class="artist">{{ title }}</div>
          </div>
          <div class="btn btn-prev">
            <a href="" @click.prevent="$emit('prev', true)"
              ><img src="~@/assets/i/player-prev.svg" alt=""
            /></a>
          </div>
          <div class="btn btn-play" @click.prevent="play()" v-if="!playing">
            <a href=""><img src="~@/assets/i/player-play.svg" alt=""/></a>
          </div>
          <div class="btn btn-play" @click.prevent="pause()" v-else>
            <a href=""><img src="~@/assets/i/player-pause.svg" alt=""/></a>
          </div>
          <div class="btn btn-next">
            <a href="" @click.prevent="$emit('next', true)"
              ><img src="~@/assets/i/player-next.svg" alt=""
            /></a>
          </div>
          <div class="btn btn-shuffle">
            <a
              href=""
              :class="{ off: !shuffle }"
              @click.prevent="$emit('shuffle', !shuffle)"
              ><img src="~@/assets/i/player-shuffle.svg" alt=""
            /></a>
          </div>
          <div
            class="btn btn-repeat"
            :class="{ off: !repeat }"
            @click.prevent="$emit('repeat', !repeat)"
          >
            <a href=""><img src="~@/assets/i/player-repeat.svg" alt=""/></a>
          </div>
          <div
            class="btn btn-volume"
            @mouseenter="activeSoundBar = true"
            @mouseleave="activeSoundBar = false"
          >
            <div :style="activeSoundBarStyle">
              <input
                type="range"
                min="0"
                max="1"
                :value="volume"
                @input="onVolumeChange"
                step="0.01"
                class="volume-range"
                :style="volumeBarStyle"
              />
            </div>
            <a href="" @click.prevent="setMute"
              ><img
                :class="{ off: muted }"
                src="~@/assets/i/player-volume.svg"
                alt=""
            /></a>
          </div>
          <div class="btn btn-close">
            <a href="" @click.prevent="$emit('close', true)"
              ><img src="~@/assets/i/player-close.svg" alt=""
            /></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import cover from "@/mixins/cover";
import utils from "@/lib/utils";

export default {
  name: "Player",
  props: ["item", "title", "cover", "shuffle", "memory", "repeat"],
  mixins: [cover],
  data() {
    return {
      audio: false,
      playing: false,
      volume: 0.5,
      activeSoundBar: false,
      savedVolume: 0,
      muted: false
    };
  },
  computed: {
    timeString() {
      return utils.buildTimeString(this.item.durationSec, false);
    },
    timeStringToEnd() {
      return utils.buildTimeString(
        this.item.durationSec - this.memory.currentTime,
        false
      );
    },
    activeSoundBarStyle: {
      get() {
        if (this.activeSoundBar) {
          return {
            visibility: "unset"
          };
        }
        return {
          visibility: "hidden"
        };
      }
    },
    volumeBarStyle: {
      get() {
        return {
          "--audio-player-volume": this.volume * 100 + "%"
        };
      }
    },
    timeStyle: {
      get() {
        if (this.memory.currentTime) {
          return {
            "--audio-player-range":
              this.memory.currentTime / (this.item.durationSec / 100) + "%"
          };
        }
        return {
          "--audio-player-range": "0%"
        };
      }
    }
  },
  methods: {
    onPlaying() {
      this.playing = true;
    },

    onPause() {
      this.playing = false;
    },
    seekTo(value) {
      this.audio.currentTime = value;
    },
    onSeekChange(event) {
      if (event.target && event.target.value) {
        this.seekTo(event.target.value);
      }
    },
    onEnded() {
      if (this.repeat) {
        this.audio.currentTime = 0;
        this.audio.play();
      } else {
        this.$emit("ended", { position: this.item.position });
      }
    },
    play() {
      this.audio.play();
      this.$emit("play", true);
    },
    pause() {
      this.audio.pause();
      this.$emit("pause", true);
    },
    onVolumeChange(event) {
      if (event.target && event.target.value) {
        this.audio.volume = parseFloat(event.target.value);
        this.volume = event.target.value;
      }
      this.muted = this.volume === 0 || this.volume === "0";
      this.audio.muted = this.muted;
    },
    setMute() {
      this.muted = !this.muted;
      if (this.muted) {
        this.savedVolume = this.volume;
        this.volume = 0;
      } else {
        this.volume = this.savedVolume;
      }
      this.audio.muted = this.muted;
    },
    onDurationChange(event) {
      this.play();
      this.duration = event.target.duration;
    },

    onTimeUpdate(event) {
      this.$emit("timeUpdate", {
        currentTime: event.target.currentTime,
        position: this.item.position
      });
    },
    setPlayerEvents() {
      // https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Media_events
      this.audio.addEventListener("playing", this.onPlaying);
      this.audio.addEventListener("pause", this.onPause);
      this.audio.addEventListener("durationchange", this.onDurationChange);
      this.audio.addEventListener("timeupdate", this.onTimeUpdate);
      this.audio.addEventListener("volumechange", this.onVolumeChange);
      this.audio.addEventListener("ended", this.onEnded);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.audio = this.$refs.audio;
      this.setPlayerEvents();
      this.play();
    });
  }
};
</script>

<style scoped>
.off {
  opacity: 40%;
}
</style>
