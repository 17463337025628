import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("flight", {
      isFlight: state => state.isFlight,
      crewExist: state => state.crewExist,
      isUpgrade: state => state.isUpgrade,
      isFoodMenu: state => state.isFoodMenu,
      isService: state => state.isService
    }),
    ...mapState("user", {
      isAuth: state => state.authenticate
    })
  }
};
