<template>
  <div>
    <header-core @openNavigation="openNavigation = true"></header-core>
    <router-view :shuffle="shuffle" />
    <Login v-if="showLoginModal"></Login>

    <OrderHeader></OrderHeader>
    <Player
      :title="contentData.title"
      :cover="contentData.cover"
      :memory="activeMemory"
      :item="activeTrack"
      :shuffle="shuffle"
      :repeat="repeat"
      @close="disablePlayer"
      @prev="prev()"
      @next="next()"
      @shuffle="toggleShuffle"
      @repeat="toggleRepeat"
      @timeUpdate="currentTimeSec"
      @ended="onEnded"
      v-if="activeTrack && activePlayer"
    />

    <div class="player-placeholder"></div>
    <footer-core
      v-if="indexSectionLoading === false && $route.name !== 'Feedback'"
      :items="indexSectionData"
    ></footer-core>
    <Navigation
      :open="openNavigation"
      @close="openNavigation = false"
      :items="indexSectionData"
    ></Navigation>
  </div>
</template>

<script>
import FooterCore from "@/layouts/Footer";
import HeaderCore from "@/layouts/Header";
import Login from "@/components/popup/Login";
import { mapActions, mapMutations, mapState } from "vuex";
import OrderHeader from "@/components/popup/OrderHeader";
import Player from "@/components/audio/Player";
import utils from "@/lib/utils";
import Navigation from "@/components/popup/Navigation";
export default {
  name: "MainPortal",
  title() {
    return `Россия ife — ${this.$t("pages.main")}`;
  },
  components: {
    Navigation,
    Player,
    OrderHeader,
    HeaderCore,
    FooterCore,
    Login
  },
  computed: {
    ...mapState("user", {
      showLoginModal: state => state.showModal
    }),
    ...mapState("section", {
      indexSectionData: state => state.index,
      indexSectionLoading: state => state.indexLoading
    }),
    ...mapState("flight", {
      flightDetailsLoading: state => state.flightDetailsLoading,
      flightLoading: state => state.flightLoading
    }),
    ...mapState("audio", {
      contentData: state => state.content,
      advertiseData: state => state.advertise,
      itemLoading: state => state.itemLoading,
      memory: state => state.memory,
      activePlayer: state => state.activePlayer
    }),
    ...mapState({
      locale: state => state.locale
    }),
    tracks() {
      if (this.shuffle) {
        return utils.shuffle(this.contentData.contentItems);
      } else {
        return this.contentData.contentItems;
      }
    },
    activeTrack() {
      if (this.memory && this.memory.findIndex(item => item.active) !== -1) {
        return this.contentData.contentItems[
          this.memory.findIndex(item => item.active)
        ];
      }
      return false;
    },
    activeMemory() {
      return this.memory.find(item => item.active);
    },
    playList() {
      let newArray = [];
      for (let i = 0; i < this.tracks.length; i++) {
        newArray.push(this.tracks[i]);
        let mm = this.memory.find(
          item => item.position === this.tracks[i].position
        );
        newArray[i].active = mm ? mm.active : false;
        newArray[i].currentTime = mm ? mm.currentTime : 0;
      }
      return newArray;
    }
  },
  data() {
    return {
      repeat: false,
      shuffle: false,
      openNavigation: false
    };
  },
  watch: {
    $route: function() {
      this.openNavigation = false;
    },
    indexSectionLoading: {
      handler() {
        if (this.indexSectionLoading === "empty") {
          this.actionSectionIndex();
        }
      },
      immediate: true
    },
    showLoginModal(value) {
      if (value) {
        document.documentElement.classList.add("modal-opened");
      } else {
        document.documentElement.classList.remove("modal-opened");
      }
      console.log(value);
    }
  },
  methods: {
    ...mapActions("section", {
      actionSectionIndex: "index"
    }),
    ...mapActions("flight", {
      actionFlightIndex: "flight"
    }),
    currentTimeSec(payload) {
      this.memory.find(item => item.position === payload.position).currentTime =
        payload.currentTime;
    },
    prev() {
      for (let i = 0; i < this.memory.length; i++) {
        if (this.memory[i].active) {
          this.memory[i].active = false;
          if (i !== 0) {
            this.memory[i - 1].active = true;
          } else {
            this.memory[this.memory.length - 1].active = true;
          }
          break;
        }
      }
    },
    next() {
      for (let i = 0; i < this.memory.length; i++) {
        if (this.memory[i].active) {
          this.memory[i].active = false;
          if (i === this.memory.length - 1) {
            this.memory[0].active = true;
          } else {
            this.memory[i + 1].active = true;
          }
          break;
        }
      }
    },
    toggleShuffle(payload) {
      this.shuffle = payload;
    },
    toggleRepeat(payload) {
      this.repeat = payload;
    },
    onEnded(payload) {
      for (let i = 0; i < this.memory.length; i++) {
        this.memory[i].active = false;
      }
      let mmIndex = this.memory.findIndex(
        item => item.position === payload.position
      );
      if (this.memory[mmIndex + 1]) {
        this.memory[mmIndex + 1].active = true;
      } else if (this.memory[0]) {
        this.memory[0].active = true;
      }
    },
    disablePlayer() {
      if (!this.memory.length) {
        for (let i = 0; i < this.contentData.contentItems.length; i++) {
          this.memory.push({ active: false });
        }
      } else {
        for (let i = 0; i < this.memory.length; i++) {
          this.memory[i].active = false;
        }
      }
      this.setEntityAudio(["activePlayer", false]);
    },
    ...mapMutations("audio", {
      setEntityAudio: "SET_ENTITY"
    })
  },
  mounted() {
    document.querySelector("html").setAttribute("lang", this.$i18n.locale);
  }
};
</script>
